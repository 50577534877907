import React, { Suspense,lazy } from "react";
import { Navigate, Route, Routes } from "react-router";
import InvalidConsultation from "./components/404/InvalidConsultation";

const InnerContent = lazy(() => import("./utils/routes/InnerContent"));
const ProtectedRoutes = lazy(() => import("./utils/routes/ProtectedRoutes"));
const AgentRoutes = lazy(() => import("./utils/routes/AgentRoutes"));
const NurseRoutes = lazy(() => import("./utils/routes/NurseRoutes"));
const PublicRoutes = lazy(() => import("./utils/routes/PublicRoutes"));
const Dashboard = lazy(() => import("./pages/protected/Dashboard.page"));
const MyConsultations = lazy(() => import("./pages/protected/MyConsultations.page"));
const Login = lazy(() => import("./pages/public/Login"));
const Register = lazy(() => import("./pages/public/Register"));
const AuthLoader = lazy(() => import("./components/login/AuthLoader"));
const ResetPassword = lazy(() => import("./components/resetPassword/ResetPassword"));
const ForgotPassword = lazy(() => import("./components/forgotPassword/ForgotPassword"));
const BankDetails = lazy(() => import("./pages/protected/BankDetails.page"));
const ChangePassword = lazy(() => import("./pages/protected/ChangePassword.page"));
const PersonalInfo = lazy(() => import("./pages/protected/PersonalInfo.page"));
const ServiceInfo = lazy(() => import("./pages/protected/ServiceInfo.page"));
const Professional = lazy(() => import("./pages/protected/Professional.page"));
const ConsultationDetails = lazy(() => import("./pages/protected/ConsultationDetails.page"));
const Consultations = lazy(() => import("./pages/protected/Consultations.page"));
const DermacareConsultation = lazy(() => import("./pages/protected/DermacareConsultation.page"));
const SecondOpinionDashboard = lazy(() => import("./pages/protected/SecondOpinionDashboard.page"));
const Calander = lazy(() => import("./pages/protected/Calander.page"));
const Roster = lazy(() => import("./pages/protected/Roster.page"));
const SAFDashboard = lazy(() => import("./pages/protected/SAFDashboard.page"));
const DermaDashboard = lazy(() => import("./pages/protected/DermaDashboard.page"));
const Billing = lazy(() => import("./pages/protected/Billing.page"));
const Inbox = lazy(() => import("./pages/protected/Inbox.page"));
const Patients = lazy(() => import("./pages/protected/Patients.page"));
const Patient = lazy(() => import("./pages/protected/Patient.page"));
const Guest = lazy(() => import("./pages/public/Guest.page"));
const Nurse = lazy(() => import("./pages/public/Nurse.page"));
const Byod = lazy(() => import("./pages/public/Byod.page"));
const MRNContainer = lazy(() => import("./pages/protected/MRNContainer"));
const Vaccations = lazy(() => import("./pages/protected/Vaccations.page"));
const Clinics = lazy(() => import("./pages/protected/Clinics.page"));
const SharedDocs = lazy(() => import("./pages/protected/SharedDocs.page"));
const NotFound = lazy(() => import("./pages/protected/NotFound.page"));
const Maintainence = lazy(() => import("./pages/protected/Maintainence.page"));
const MaintainenceAgent = lazy(() => import("./pages/agent/MaintainenceAgent.page"));
const Agent = lazy(() => import("./pages/public/Agent.page"));
const AgentDashboard = lazy(() => import("./pages/agent/AgentDashboard.page"));
const AgentSODashboard = lazy(() => import("./pages/agent/AgentSODashboard.page"));
const AgentDiagnosticDashboard = lazy(() => import("./pages/agent/AgentDiagnosticDashboard.page"));
const AgentAlertDashboard = lazy(() => import("./pages/agent/AgentAlertDashboard.page"));
const AgentRedcliffDashboard = lazy(() => import("./pages/agent/AgentRedcliffDashboard.page"));
const HomecareSerivce = lazy(() => import("./pages/agent/HomecareSerive.page"));
const AgentPublicRoutes = lazy(() => import("./utils/routes/AgentPublicRoutes"));
const HomecareDashboard = lazy(() => import("./pages/protected/HomecareDashboard.page"));
const HomecareServiceDetails = lazy(() => import("./pages/protected/HomecareServiceDetails.page"));
const NurseDashboard = lazy(() => import("./pages/protected/NurseDashboard.page"));
const NurseServiceDetails = lazy(() => import("./pages/nurse/NurseServiceDetails"));
const NurseConsultation = lazy(() => import("./pages/nurse/NurseConsultation.page"));
const MainRoutes = () => {
  return (
    <Suspense fallback={<AuthLoader/>}>
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<InnerContent />}>
            <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="my-consultations" element={<MyConsultations />} />
            <Route path="consultations/:id" element={<ConsultationDetails />} />
            <Route path="consultation/:id/:provider" element={<Consultations />} />
            <Route path="derma-consultation/:id" element={<DermacareConsultation />} />
            <Route path="home-service-details/:id" element={<HomecareServiceDetails />} />
            <Route path="skincare-saf" element={<SAFDashboard />} />
            <Route path="dermacare" element={<DermaDashboard />} />
            <Route path="second-opinion" element={<SecondOpinionDashboard />} />
            <Route path="home-care" element={<HomecareDashboard />} />
            <Route path="bank-details" element={<BankDetails />} />
            <Route path="billing" element={<Billing />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="professional-info" element={<Professional />} />
            <Route path="service-info" element={<ServiceInfo />} />
            <Route path="calander" element={<Calander />} />
            <Route path="roster" element={<Roster />} />
            <Route path="vacation" element={<Vaccations />} />
            <Route path="inbox" element={<Inbox />} />
            <Route path="patients" element={<Patients />} />
            <Route path="patient/:id" element={<Patient />} />
            <Route path="mrn-license" element={<MRNContainer />} />
            <Route path="clinics" element={<Clinics />} />
            <Route path="shared-docs/:id" element={<SharedDocs />} />
            <Route path="maintainence" element={<Maintainence />} />
            <Route path="invalid" element={<InvalidConsultation />} />
            <Route path='*' element={<NotFound />} />
          </Route>
        </Route>

        <Route path="/" element={<AgentRoutes />}>
          <Route path="/" element={<InnerContent />}>
            <Route path="/" element={<Navigate replace to="agent-dashboard" />} />
            <Route path="agent-dashboard" element={<AgentDashboard />} />
            <Route path="agent-soboard" element={<AgentSODashboard />} />
            <Route path="agent-diagnostic" element={<AgentDiagnosticDashboard />} />
            <Route path="agent-alert" element={<AgentAlertDashboard />} />
            <Route path="agent-redcliff" element={<AgentRedcliffDashboard />} />
            <Route path="maintainence-agent" element={<MaintainenceAgent />} />
            <Route path="agent-service-details/:id" element={<HomecareSerivce />} />
          </Route>
        </Route>

        <Route path="/" element={<NurseRoutes />}>
          <Route path="/" element={<InnerContent />}>
            <Route path="/" element={<Navigate replace to="nurse-dashboard" />} />
            <Route path="nurse-dashboard" element={<NurseDashboard />} />   
            <Route path="nurse-service-details/:id" element={<NurseServiceDetails />} />
            <Route path="nurse-consultation/:id/:provider" element={<NurseConsultation />} />
            <Route path='*' element={<NotFound />} />
          </Route>
        </Route>


        <Route path="/" element={<PublicRoutes />}>
          <Route path="/" element={<InnerContent />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="guest/:consultId/:userId/:provider" element={<Guest />} />
            <Route path="provider-invitation/:id" element={<Byod />} />
            <Route path="nurse/:userId/:access/:refresh" element={<Nurse />} />
          </Route>
        </Route>

        <Route path="/" element={<AgentPublicRoutes />}>
          <Route path="/" element={<InnerContent />}>
           <Route path="/agent" element={<Agent />} />
        </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
