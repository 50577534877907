import nurseAPIClient from "./nurseAPIClient";
import { toast } from "react-toastify";

// Existing request methods (get, post)
const request = {
  get: (url, params) =>
    nurseAPIClient
      .get(url, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          // Handle unauthorized error
          // localStorage.clear();
          // window.location = "/login";
        } else if (res?.response?.status === 500) {
          toast.error(
            "Oops something went wrong, please try again after sometime or contact Merago Support"
          );
        }
      }),
  post: (url, data, params) =>
    nurseAPIClient
      .post(url, data, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          // Handle unauthorized error
          // localStorage.clear();
          // window.location = "/login";
        } else if (res?.message === "Network Error") {
          window.location = "/maintainence";
        }
      }),
  put: (url, data, params) =>
    nurseAPIClient
      .put(url, data, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          // localStorage.clear();
          window.location = "/agent";
        } else if (res?.message === "Network Error") {
          window.location = "/maintainence";
        }
      }),
};

//! 1-API
//?1. Alert - Nurse
export const getAgentAlertWithFilterNurse = (data) =>
  request.post("/merago/api/provider/agent/v1/firstAlertNotifications", data);
//* 4.Get Shared Records
export const getSharedRecordsNurse = (params) =>
  request.get("/merago/api/provider/getSharedDocuments", params);
//* 1.Get COD Details
export const getCODDetails = (params) =>
  request.get("/merago/api/provider/urgentcare/details", params);
//* 2.Get Profile Values
export const getProfileValues = (params) =>
  request.get("/merago/api/customer/getProfileValues", params);
//* 3.Get Profile DP
export const getProfileDP = (params) =>
  request.post("/merago/api/storage/getDPURL", "", params);
//? 8.Start Service By Agent
export const nurseJoinConsultation = (data) =>
  request.post("/merago/api/provider/urgentCareConsultation/join", data);
//* 5.Get Consultations
export const getConsultations = (params) =>
  request.get("/merago/api/provider/getConsultationForProvider", params); //?1. Alert - Nurse
export const updateUrgentCareNurseComments = (data) =>
  request.post("/merago/api/provider/v1/updateUrgentCareNurseComments", data);
//* 1.Get Agent Details
export const getAgentByUserId = (params) =>
  request.get("/merago/api/provider/agent/v1/agentById", params);
