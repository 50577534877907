import { useMutation, useQuery } from "react-query";
import * as nurse from "../services/nurseAPI";
const _ = require("lodash");

//? AlertU List
export const useNurseAlertWithFilter = () => {
  return useMutation((data) => nurse.getAgentAlertWithFilterNurse(data), {
    retry: false,
  });
};
//* Get Shared Records
export const useGetSharedRecordsNurse = (params) => {
  return useQuery(
    ["Shared-Records", params],
    () => nurse.getSharedRecordsNurse({ consultationId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      refetchInterval: 8000,
    }
  );
};
//* Get COD Details
export const useGetCODDetailsNurse = (params) => {
  return useQuery(
    ["COD-Details", params],
    () => nurse.getCODDetails({ urgenrtCareReqId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      refetchInterval: 5000,
    }
  );
};
//* Get Profile Values
export const useGetProfileValuesNurse = (params) => {
  return useQuery(
    ["Profile-Values", params],
    () => nurse.getProfileValues(params),
    {
      enabled: !!params?.memberId,
      retry: false,
    }
  );
}; //* Get Profile DP
export const useGetProfileDPNurse = (params) => {
  return useQuery(["Profile-DP", params], () => nurse.getProfileDP(params), {
    enabled: !!params?.userId,
    retry: false,
  });
};

//? Update Start Service Status
export const useNurseJoinConsultation = () => {
  return useMutation((data) => nurse.nurseJoinConsultation(data), {
    retry: false,
  });
};

//* Get Consultations by consultation number
export const useGetConsultationsNurse = (params) => {
  return useQuery(
    ["Consultations", params],
    () => nurse.getConsultations({ consultationNumber: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      refetchInterval: 5000,
    }
  );
};
//? Update Nurse Leave
export const useUpdateUrgentCareNurseComments = () => {
  return useMutation((data) => nurse.updateUrgentCareNurseComments(data), {
    retry: false,
  });
};
//* Get Agent Details
export const useGetNurseByUserId = (params) => {
  return useQuery(
    ["Agent-Details", params],
    () => nurse.getAgentByUserId({ userId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};
